.valueBox {
    display: flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 10px;
    
}
.valueBox .labelBox {
    
    text-align: end;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.valueBox .labelBox span {
    width: 30px;
    margin-left: 10px;
}

.valueBox .progressBox {
    width: 50%;
    text-align: start;
    background-color: #555555;
    padding: 3px;
    border-radius: 20px;
}

.valueBox .progressBox .progressBar {
    border-radius: 20px;
    background-color: #2BC253;
    text-align: center;
    font-size: 0.7em;
    height: 10px;    
}

.valueBox .emptyProgressBox {
    /* background-image: url('../../../public/stripe_81ba718ce419df030ad28133b0ac262e.png'); */

    background-color: transparent;
    
}

.valueBox .progressBox .redBar {
    background-color: rgb(255, 2, 2);
}