.containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: aquamarine;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logoBox {
    width: 70%;
}
.logoBox img {
    width: 90%;
}

.buttonStart {
    margin-top: 50px;
    width: 150px;
    padding: 10px;
    background-color: #3371b4;
    color: white;
    font-size: 1.1em;
}

.imagesBox {
    margin-top: 50px;
    background-color: #43dc6c;
    border-radius: 20px;
    padding: 10px 0px;
    width: 60%;
}

.imagesBox .character {
    width: 15%;
    transition: transform .2s;
}

.imagesBox .character:hover {
    transform: scale(1.15);
}


.searchingImage {
    height: 0.9em;
    margin-left: 10px;
}