.footerBox {
    height: 100px;
    border-top: 2px solid green;

    background-color: #F4F3F3;
    display: flex;
    justify-content: space-around;

    padding-top: 10px;
}

.footerBox .listSocialMedias {
    display: flex;
}

.footerBox .iconSocialMedia {
    margin: 10px;
    font-size: 1.3em;
    cursor: pointer;
}