.filterBox {
    background-color: white;

    text-align: start;

    padding: 15px;
    border: 4px solid #F4F3F3;

    border-radius: 20px;
    margin-bottom: 15px;
}

.title {
    font-weight: bold;
    
}

.optionBox {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.optionBox select {
    margin-left: 20px;
    margin-right: 10px;
    width: 100%;
    font-size: 1em;

}

.optionBox select option {
    
}
