.searchBox {
    background-color: white;
    border: 4px solid #F4F3F3;
    border-radius: 20px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.searchBox .errorBox {
    display: flex;

}
.searchBox .errorBox .errorMessage{
    margin: 0px;
    padding: 0px;
    font-size: 0.8em;
    color: rgba(255, 0, 0, 0.63);
    
}

.searchInput {
    display: flex;
    align-items: center;

}

.searchInput .inputBox {
    border: 1px solid black;
    display: flex;
    align-items: center;
}
.searchingImage {
    height: 0.9em;
    margin-left: 10px;
    margin-right: 10px;
}


.searchInput input {
    font-size: 1.2em;
    border: none;
    width: 100%;
    
}

.searchInput input:focus {
    outline: none;
}

.searchInput button {
    background-color: #cc0000;
    color: #fccaca;
    padding: 3px;
    width: 80px;
    margin: 0px 10px;
    
}

.searchInput button:hover {
    background-color: #f60000;
    color: white;
}



