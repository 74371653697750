.card {
    /* width: calc(25% - 40px);
    margin: 16px; */

    width: calc(25% - 40px);
    margin: 16px;

    border-radius: 20px;
    border: 4px solid white;

    /* background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(5,11,26,1) 40%); */
    background-color: rgb(244, 243, 243);

    opacity: 0;

    transition: all 0.5s ease-in-out;

}

.cardVisible {
    opacity: 1;
}

.cardImage img {
    width: 90%;
    transition: all 0.3s ease-in-out;
}

.cardImage img:hover {
    transform: scale(1.05);
}


.extCardName {
    /* background-color: #fbcc42; */
    /* overflow: hidden; */
    /* border: 2px solid #3665ab; */
    /* padding: 3px; */
    /* border-radius: 10px; */
}

.cardName {
    /* background-color: #fbcc42; */
    /* background-color: aqua; */

}
.name {
    color: #3665ab;
    font-weight: bold;
    font-size: 1.5em;
}

.cardTypes {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    margin-bottom: 7px;
}

.cardTypes .iconBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .card {
        /* width: calc(25% - 40px);
        margin: 16px; */
    
        width: 90%;
        margin: 16px;
    }

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .card {
        width: calc(50% - 40px);
        margin: 16px;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .card {
        width: calc(50% - 40px);
        margin: 16px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .card {
        width: calc(33% - 40px);
        margin: 16px;
    }

    
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .card {
        /* width: calc(25% - 40px);
        margin: 8px; */

        width: calc(25% - 40px);
        margin: 8px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1700px) {
    
}