.container{
    display: flex;

}

.sidebar {
    width: 300px;
}

.home {
    width:100%;
}

.searchingImage {
    height: 1.5em;
    top: 50%;
    position: absolute;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .container {        
        flex-direction: column;
        align-items: center;
    }

    .sidebar {
        width: 90%;
    }

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .container {        
        flex-direction: column;
        align-items: center;
    }

    .sidebar {
        width: 90%;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .container {        
        flex-direction: row;
        align-items: start;
    }

    .sidebar {
        width: 300px;
        margin-left: 20px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
    
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1700px) {
    
}