.filterBox {
    background-color: white;

    text-align: start;

    padding: 15px;
    border: 4px solid #F4F3F3;

    border-radius: 20px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.title {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}

.optionBox {
    display: flex;
    justify-content: space-between;
}

.optionBox input {
    margin-left: 20px;
    margin-right: 10px;
}

.optionBox .count {
    margin-right: 20px;
    color: grey;
}