.small {
    width: 30px;
}

.xsmall {
    width: 20px;
}

.disabled {
    opacity: 0.7;
}